/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

button.mat-primary {
    color: white !important;
}

button.mat-primary mat-icon svg {
    stroke: white !important;
}


.report-container {
    height: 81vh;
    margin: 8px auto;
    width: 160vh;
}

.parent {
    text-align: center;
  }
  .child {
    display: inline-block;
    vertical-align: top;
  }

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix .search{
    padding-top: 0.1px !important;
    padding-bottom: 0.1px !important;
}
  
.mat-mdc-form-field-infix {
    min-height: 10px !important;
}

.add-button{
    padding-top: 0.1px !important;
    padding-bottom: 0.1px !important;
}

.mdc-notched-outline__notch{
  border-right: none;
}


mat-form-field.pr-2 {
    padding: 5px;
}