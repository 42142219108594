/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

h1 {
  font-size: 1.3rem !important;
  text-align: center !important;
  padding-top: 12px;
}
